


























































































































































import { computed, onUnmounted, ref } from '@vue/composition-api';
import store, { useUserActions, useUserGetters, useUserState } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';

export default {
  name: 'MVideoask',
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    adkType: {
      type: String,
      default: ''
    },
    userType: {
      required: false,
      type: String,
      default: ''
    },
    videoAskUrl: {
      required: true,
      type: String
    },
    keyName: {
      required: false,
      type: String,
      default: ''
    },
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    studentId: {
      type: String,
      default: ''
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: ''
    }
  },
  setup(props: any, ctx) {
    const { deleteVideoAskResponse, reAttachVideoaskResponse } = useUserActions([
      'deleteVideoAskResponse',
      'reAttachVideoaskResponse'
    ]);
    const { getProgramDoc } = useUserGetters(['getProgramDoc']);
    const { user }: any = useUserState(['user']);
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const isSponsored = programDoc.value?.data?.isProgramCheckout;

    const videoAskUrlValue = computed({
      get: () => props?.videoAskUrl,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkData = computed(() => {
      return programDoc?.value?.data?.adks?.find(a => a?.name === props?.adkType);
    });

    const videoaskModel = computed(() => {
      return (
        programDoc?.value?.data?.adks
          ?.find(a => a?.name === props?.adkType)
          ?.fields?.find(field => field?.name === 'm-videoask')?.model ||
        programDoc?.value?.data?.adks
          ?.find(a => a?.name === props?.adkType)
          ?.presetFields?.find(field => field?.name === 'm-videoask')?.model
      );
    });

    const fieldValue = computed(() => {
      if (props.mode === 'build') {
        return (
          programDoc.value?.fields?.find(f => f.name === 'm-videoask') ||
          programDoc.value?.presetFields?.find(f => f.name === 'm-videoask')
        );
      }
      return (
        adkData.value?.fields?.find(f => f.name === 'm-videoask') ||
        adkData.value?.presetFields?.find(f => f.name === 'm-videoask')
      );
    });

    const videoaskResponseValue = computed(() => {
      return props.userType === 'employer'
        ? fieldValue.value?.setup[props.keyName]
        : fieldValue.value?.value[props.keyName];
    });

    const keyNameValue = computed({
      get: () => props.keyName,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const userTypeValue = computed({
      get: () => props.userType,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const redoWelcome = ref(false);
    const isFetching = ref(false);
    const isLoading = ref(false);

    function fetchStatus() {
      isFetching.value = true;
      try {
        const setFunction = setInterval(() => {
          if (videoaskResponseValue.value) {
            clearInterval(setFunction);
          } else {
            props.fetchProgram();
          }
        }, 10000);
        setTimeout(() => {
          isFetching.value = false;
        }, 2000);
      } catch (error) {
        console.log(error);
        isFetching.value = false;
      }
    }

    const programIdValue: any = computed(() => {
      if (userTypeValue.value === 'participant') {
        return programDoc.value.data.program_id.toString();
      }
      return programDoc.value.data._id.toString();
    });

    function deleteVideo() {
      isLoading.value = true;
      setTimeout(async () => {
        await deleteVideoAskResponse({
          user_type: userTypeValue.value,
          program_id: programIdValue.value,
          public_id: userTypeValue?.value === 'participant' ? programDoc?.value.data?._id : null,
          adk_name: props.adkType,
          key_name: keyNameValue.value
        });
        programDoc.value.data = {
          adks: [],
          ...getProgramDoc.value
        };
        isLoading.value = false;
        redoWelcome.value = false;
      }, 2000);
    }

    const intervalId = window.setInterval(() => {
      if (videoaskResponseValue.value) {
        clearInterval(intervalId);
        ctx.emit('videoAskCompleted');
      } else if (props.fetchProgram) {
        props.fetchProgram();
      }
    }, 10000);

    onUnmounted(() => {
      clearInterval(intervalId);
    });

    const displayMessage = ref('');

    const reAttachVideoask = async () => {
      isFetching.value = true;
      const resp = await reAttachVideoaskResponse({
        user_id: user?.value?._id,
        program_id: programIdValue.value,
        adk_name: props.adkType
      });
      if (!resp?.error) {
        props.fetchProgram();
        setTimeout(() => {
          isFetching.value = false;
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: resp?.message,
            type: 'success',
            isShowSnackbar: true
          });
        }, 3000);
      } else {
        props.fetchProgram();
        isFetching.value = false;
        displayMessage.value = resp?.message;
        setTimeout(() => {
          displayMessage.value = '';
        }, 10000);
      }
    };

    return {
      videoaskResponseValue,
      adkData,
      videoaskModel,
      redoWelcome,
      isFetching,
      fetchStatus,
      isLoading,
      deleteVideo,
      fieldValue,
      videoAskUrlValue,
      isSponsored,
      reAttachVideoask,
      displayMessage
    };
  }
};
